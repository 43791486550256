<template>
  <div class="exportable-bar-chart">
    <bar-chart class="chart-container" :chart-data="chartData" :options="options" />
    <button @click.prevent="exportData" class="button download-button"><img class="icon" src="/icons/download.svg"></button>
  </div>
</template>

<script>
import BarChart from '@/components/shared/charts/BarChart'
import exportService from '@/services/export-service'
import C2S from 'canvas2svg'
import Chart from 'chart.js'

export default {
  components: {
    BarChart
  },
  props: {
    chartData: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  methods: {
    createSvg () {
      // Testspace for exporting SVG. Doesnt work yet...

      var svgContext = C2S(600, 600)
      let options = { ...this.options }
      // deactivate responsiveness and animation
      options.responsive = false
      options.animation = false
      let graphData = {
        'type': 'bar',
        'data': this.chartData,
        'options': options
      }

      var mySvg = new Chart(svgContext, graphData)
      mySvg.width = 600
      mySvg.height = 600
    },
    exportData () {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    }
  }
}
</script>
<style>
.exportable-bar-chart {
  position: relative;
}

.exportable-bar-chart .download-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.exportable-bar-chart .chart-container {
  position: relative;
}
</style>
