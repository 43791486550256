<template>
  <div>
    <div class="tabs">
      <a class="tabs-title" v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)" :class='{"selected": (index == selectedIndex)}'>{{ tab.title }}</a>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>
<style scoped>
.tabs {
  margin-bottom: var(--spacing-s);
}
.tabs > a {
  display: inline-block;
  padding: var(--spacing-xs);
  margin: var(--spacing-xs);
  cursor: pointer;
}
.tabs > a.selected {
  border-bottom: 2px solid var(--secondary-text-color);
}
</style>
