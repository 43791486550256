<!--
PortfolioBuildingsMixin:
Loads buildings for a specific portfolio

Provides: portfolio.buildings, portfolioBuildingsLoading, portfolioBuildingsReady
Required: PortfolioMixin
-->
<script>
import { mapActions } from 'vuex'

export default {
  computed: {
    portfolioBuildingsLoading () {
      return this.portfolio !== undefined && this.portfolio.__buildings_loading
    },
    portfolioBuildingsReady () {
      return this.portfolio !== undefined && this.portfolio.__buildings_loaded
    }
  },
  created () {
    this.__loadBuildingsById(this.portfolio_id)
  },
  watch: {
    portfolio () {
      this.__loadBuildingsById(this.portfolio_id)
    }
  },
  methods: {
    ...mapActions({
      __loadBuildingsById: 'portfolio/loadBuildingsById'
    })
  }
}
</script>
